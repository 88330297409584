<template>
  <v-dialog
    :value="dialog"
    @input="$emit('cerrar')"
    out
    :width="$vuetify.breakpoint.smAndDown ? 'unset' : width"
    :max-width="$vuetify.breakpoint.smAndDown ? 'unset' : width"
    scrollable
    :persistent="persistent"
    :fullscreen="fullscreen"
  >
    <v-card>
      <v-card-title class="secondary py-1 px-2 flex-column align-stretch">
        <v-row no-gutters>
          <v-col class="subtitle white--text text-truncate">
            {{ titulo }}
          </v-col>
          <v-col cols="auto">
            <v-btn dark class="ml-2" icon tile @click="$emit('cerrar')">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <slot name="cabecera"></slot>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-5">
        <!--        <v-container>-->
        <!-- @slot usar para el cuerpo del modal -->
        <slot></slot>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions v-if="!ocutarFooter">
        <v-spacer></v-spacer>
        <v-btn @click="cancelar" color="primary" outlined text>Cancelar</v-btn>
        <!-- @slot usar para el footer del modal a lado del boton cancelar -->
        <slot name="acciones">
          <v-btn color="primary" @click="aceptar">Aceptar</v-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  model: {
    prop: "dialog",
    event: "cerrar",
  },
  props: {
    /**
     * Agrega titulo
     */
    titulo: {
      type: String,
      default: "",
    },
    /**
     * Activa el full pantall del dialog
     * @values true, false
     * @default false
     */
    fullscreen: {
      type: Boolean,
      default: false,
    },
    /**
     * Activa o desactiva la persistencia del dialog
     * @values true, false
     * @default false
     */
    persistent: {
      type: Boolean,
      default: false,
    },
    /**
     * Valor del modal
     * @values true, false
     * @default false
     */
    dialog: {
      type: Boolean,
      default: false,
    },
    /**
     * Oculta el pie de pagina del dialog
     * @values true, false
     * @default false
     */
    ocutarFooter: {
      type: Boolean,
      default: false,
    },
    /**
     * Oculta el pie de pagina del dialog
     * @values true, false
     * @default false
     */
    width: {
      type: String,
      default: "unset",
    },
  },
  methods: {
    aceptar() {
      this.$emit("aceptar");
      this.$emit("cerrar");
    },
    cancelar() {
      this.$emit("cancelar");
      this.$emit("cerrar");
    },
  },
};
</script>

<style lang="scss" scoped></style>
