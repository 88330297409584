<template>
    <div>
        <v-btn
            :text="text"
            :outlined="outlined"
            :rounded="rounded"
            :tile="tile"
            type="submit"
            :disabled="disabled"
            @click="$emit('click')"
            color="primary"
            class="ma-1"
        >{{texto}}
      </v-btn>
    </div>
</template>
<script>
export default {
    data: () => ({
  }),
  props:{
       texto:{
           type: String, 
           require:true,
           default:"Boton"
       },
       disabled:{
           type: Boolean,
           require:false,
           default:false
       },
       text:undefined,
       outlined:undefined,
       rounded:undefined,
       tile:undefined,
    }
}
</script>