<template>
    <v-container fluid class="fill-height align-start" >
      <v-row>
        <v-col class="pb-1"> <h4>{{titulo}}</h4></v-col>
         
      </v-row>
      <v-row>

        <v-col class="pt-0">
          <slot></slot>
        </v-col>
      </v-row>
    </v-container>
</template>
<script>
export default {
  props: {
      titulo: {
        require:true,
        type:String,
      },
        subTitulo: {
        require:false,
        type:String,
      }
    },
};
</script>

<style lang="scss" scoped></style>
